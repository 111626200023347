import * as React from "react"
import {styled} from '@material-ui/core/styles';
import {graphql} from "gatsby"
import LayoutTmpl from "../components/layout";
import Container from "@material-ui/core/Container";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import ButtonBase from "@material-ui/core/ButtonBase";
import {GatsbyImage} from "gatsby-plugin-image";
import CardContent from "@material-ui/core/CardContent";
import Rating from "@material-ui/lab/Rating";
import {Helmet} from "react-helmet";
import Links from '@material-ui/core/Link';
import Chip from "@material-ui/core/Chip";

const StyledHeroDiv = styled('div')(({theme}) => ({
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(2, 0, 1),
}));
const StyledContainer = styled(Container)(({theme}) => ({
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(8),
}));
const StyledCard = styled(Card)(({theme}) => ({
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
}));
const StyledCardContent = styled(CardContent)(({theme}) => ({
    flexGrow: 1,
}));
const StyledRatingDiv = styled('div')(({theme}) => ({
    padding: 0,
    margin: 0,
}));

const TagTemplate = ({data}) => {

    const row = data.pageTags
    const images = data.allPageImages.nodes;

    return (
        <>
            <Helmet defer={false}>
                <html lang="ru-RU"/>
                <title>{row.tag.name}</title>
                <meta name="description" content={"Все записи по тегу " + row.tag.name}/>
                <meta name="keywords" content={row.tag.name}/>
                <meta name="robots" content="index, follow"/>
                <meta name="x-robots-tag" content="all"/>
                <meta property="og:type" content="article"/>
                <meta property="og:site_name" content={"Ocenivay.com"}/>
                <meta property="og:title" content={row.tag.name}/>
                <meta property="og:description" content={"Все записи по тегу " + row.tag.name}/>
                <meta property="og:url" content={"https://ocenivay.com" + row.tag.url}/>
                <meta property="og:locale" content="ru_RU"/>
                <meta property="og:image" content="https://ocenivay.com/logo.png"/>
                <meta http-equiv="cache-control" content="no-cache"/>
                <meta http-equiv="expires" content="0"/>
                <meta http-equiv="pragma" content="no-cache"/>
            </Helmet>

            <LayoutTmpl>

                <main>
                    <StyledHeroDiv>
                        <Container maxWidth="md">
                            <Typography component="h1" variant="h5" align="left" color="textPrimary" gutterBottom>
                                {row.tag.name} - все записи по тегу
                            </Typography>
                        </Container>
                    </StyledHeroDiv>
                    <StyledContainer maxWidth="md">

                        <Grid container spacing={4}>

                            {row.tag.elements.map((row, i) => {

                                return (

                                    <Grid item key={i} xs={12} sm={6} md={4}>
                                        <StyledCard>
                                            <ButtonBase>
                                                {
                                                    images.map((image) => {

                                                        return (
                                                            (image.element === row.element.id ? image.localImages[0].childImageSharp === null ? '' :
                                                                <Links href={row.element.url} key={i}>
                                                                    <GatsbyImage
                                                                        image={image.localImages[0].childImageSharp.gatsbyImageData}
                                                                        alt={row.element.name}/>
                                                                </Links> : "")
                                                        )
                                                    })
                                                }
                                            </ButtonBase>

                                            <StyledCardContent>
                                                <Grid
                                                    container
                                                    direction="row"
                                                    justifyContent="flex-start"
                                                    alignItems="flex-start"
                                                >
                                                    <Grid>
                                                        <StyledRatingDiv>
                                                            <Rating name="half-rating-read"
                                                                    style={{
                                                                        margin: '0 10px 0 0'
                                                                    }}
                                                                    value={parseFloat(row.element.rating)}
                                                                    precision={0.5} readOnly/>
                                                        </StyledRatingDiv>
                                                    </Grid>
                                                    <Grid>
                                                        <Typography variant="body2" component="div" color="textPrimary"
                                                                    style={{
                                                                        fontSize: '0.8rem',
                                                                        margin: '3px 0 0 0'
                                                                    }}
                                                        >
                                                            <Links href={row.element.url}
                                                            >Рейтинг {
                                                                ((parseFloat(row.element.rating) < 3) &&
                                                                    <Chip
                                                                        size="small"
                                                                        label={parseFloat(row.element.rating)}
                                                                        style={{
                                                                            width: "2.7rem",
                                                                            height: "1rem",
                                                                            fontSize: '0.8rem',
                                                                            padding: '1px 0 0 0',
                                                                            margin: '-3px 0 0 0',
                                                                            backgroundColor: 'red',
                                                                            color: 'white',
                                                                        }}/>
                                                                )
                                                            }
                                                                {
                                                                    ((parseFloat(row.element.rating) >= 3 && parseFloat(row.element.rating) < 4) &&
                                                                        <Chip
                                                                            size="small"
                                                                            label={parseFloat(row.element.rating)}
                                                                            style={{
                                                                                width: "2.7rem",
                                                                                height: "1rem",
                                                                                fontSize: '0.8rem',
                                                                                padding: '1px 0 0 0',
                                                                                margin: '-3px 0 0 0',
                                                                                backgroundColor: '#F3DD10FF',
                                                                                color: 'black',
                                                                            }}/>
                                                                    )
                                                                }
                                                                {
                                                                    ((parseFloat(row.element.rating) >= 4) &&
                                                                        <Chip
                                                                            size="small"
                                                                            label={parseFloat(row.element.rating)}
                                                                            style={{
                                                                                width: "2.7rem",
                                                                                height: "1rem",
                                                                                fontSize: '0.8rem',
                                                                                padding: '1px 0 0 0',
                                                                                margin: '-3px 0 0 0',
                                                                                backgroundColor: 'green',
                                                                                color: 'white',
                                                                            }}/>
                                                                    )
                                                                } на
                                                                основе {row.element.reviews_count_number} {row.element.reviews_count_text}</Links>
                                                        </Typography>
                                                    </Grid>
                                                </Grid>

                                                <Typography gutterBottom variant="body2" component="h1"
                                                            color="textPrimary">
                                                    <Links href={row.element.url}
                                                           style={{
                                                               color: '#000000',
                                                               textShadow: 'none',
                                                               backgroundImage: 'none',
                                                               fontSize: '1.2rem',
                                                               fontWeight: 400
                                                           }}
                                                    >{row.element.name}</Links>
                                                </Typography>

                                                <Typography variant="body2" color="textPrimary" component="p"
                                                            gutterBottom>
                                                    {row.element.text} <Links href={row.element.url} style={{
                                                    textDecoration: 'underline',
                                                }}>Все отзывы</Links>
                                                </Typography>
                                                <Grid
                                                    container
                                                    direction="row"
                                                    justifyContent="flex-start"
                                                    alignItems="flex-end"
                                                >
                                                    {row.element.tags.map((tag, t) => {

                                                        return (
                                                            <Grid key={t}>
                                                                <Typography gutterBottom variant="body2"
                                                                            component="p"
                                                                            style={{
                                                                                marginTop: 10
                                                                            }}
                                                                            color="textPrimary">
                                                                    <Links href={tag.url}
                                                                           key={t}
                                                                           style={{
                                                                               color: '#3f51b5',
                                                                               textShadow: 'none',
                                                                               backgroundImage: 'none',
                                                                               fontSize: '0.8rem',
                                                                               margin: '10px'
                                                                           }}
                                                                    >#{tag.name}</Links>
                                                                </Typography>
                                                            </Grid>
                                                        )
                                                    })}
                                                </Grid>
                                            </StyledCardContent>
                                        </StyledCard>
                                    </Grid>

                                )
                            })}

                        </Grid>

                    </StyledContainer>
                </main>

            </LayoutTmpl>
        </>
    )
}
export default TagTemplate

export const pageQuery = graphql`
  query tagByUrl($url: String!) {
    site {
      siteMetadata {
        title
      }
    }
      allPageImages {
        nodes {
          element
          localImages {
            childImageSharp {
              gatsbyImageData(
                placeholder: BLURRED
                formats: [AUTO, WEBP, AVIF]
                width: 537
                height: 382
              )
            }
          }
        }
      }
      pageTags(tag: {url: {eq: $url}}) {
        tag {
          elements {
            element {
              url
              text
              tags {
                url
                name
              }
              rating
              reviews_count_text
              reviews_count_number
              name
              id
            }
          }
          url
          name
        }
      }
  }
`
